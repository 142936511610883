import React from "react";
import PropTypes from "prop-types";
import AppProvider from "~context/app-context.jsx";

import "./node_modules/modern-normalize/modern-normalize.css";
import "swiper/css";

const Provider = ({ element }) => (
  <>
    <AppProvider>{element}</AppProvider>
  </>
);

Provider.propTypes = {
  element: PropTypes.node.isRequired
};

export default Provider;
